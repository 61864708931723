body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --w3m-color-primary: #FFD700 !important;
  --w3m-color-secondary: #FFD700 !important;
  --w3m-accent-color: #FFD700 !important;
  --w3m-accent-fill-color: #FFD700 !important;
  --w3m-background-color: transparent !important;
  --w3m-overlay-background-color: rgba(0, 0, 0, 0.7) !important;
  --w3m-text-big-bold-size: 20px !important;
  --w3m-text-big-bold-weight: 600 !important;
  --w3m-text-big-bold-line-height: 24px !important;
  --w3m-text-big-bold-letter-spacing: -0.03em !important;
  --w3m-text-big-bold-color: #FFD700 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
